let mapTrackTableData = [
  {
    "id": 1,
    "vehicleId": 9671,
    "lat": 30.1176083472,
    "lng": 112.5729846242,
    "recvtime": "2020-07-06 19:14:23",
    "gpstime": "2020-07-06 19:11:51",
    "speed": 60,
    "dpf_before": "99.60",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dpf_grainbefore": "23.91",
    "dpf_grainafter": "13.19",
    "smoke_value": "28.32",
    "status": 0,
    "statusdescription": "",
    "dir": 200,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "alt": 300,
    "burner_before": "123.60",
    "urea": "98.00",
    "rownum": 1,
    "av": 1,
    "docpa_change": "123.60",
    "doc_before": "27.50",
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 2,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:14:28",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 2,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:11:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 3,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:14:33",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 3,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:12:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 4,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:14:38",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 4,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:12:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 5,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:14:43",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 5,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:12:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 6,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:14:48",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 6,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:12:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 7,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:14:53",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 7,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:12:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 8,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:14:58",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 8,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:12:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 9,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:15:03",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 9,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:12:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 10,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:15:08",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 10,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:12:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 11,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:15:13",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 11,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:12:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 12,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:15:18",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 12,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:12:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 13,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:15:23",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 13,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:12:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 14,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:15:28",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 14,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:12:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 15,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:15:33",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 15,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:13:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 16,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:15:38",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 16,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:13:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 17,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:15:43",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 17,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:13:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 18,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:15:48",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 18,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:13:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 19,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:15:53",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 19,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:13:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 20,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:15:58",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 20,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:13:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 21,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:16:03",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 21,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:13:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 22,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:16:08",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 22,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:13:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 23,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:16:13",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 23,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:13:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 24,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:16:18",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 24,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:13:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 25,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:16:23",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 25,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:13:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 26,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:16:28",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 26,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:13:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 27,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:16:33",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 27,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:14:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 28,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:16:38",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 28,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:14:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 29,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:16:43",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 29,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:14:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 30,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:16:48",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 30,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:14:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 31,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:16:53",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 31,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:14:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 32,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:16:58",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 32,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:14:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 33,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:17:03",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 33,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:14:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 34,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:17:08",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 34,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:14:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 35,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:17:13",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 35,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:14:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 36,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:17:18",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 36,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:14:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 37,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:17:23",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 37,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:14:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 38,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:17:28",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 38,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:14:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 39,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:17:33",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 39,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:15:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 40,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:17:38",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 40,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:15:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 41,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:17:43",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 41,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:15:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 42,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:17:48",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 42,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:15:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 43,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:17:53",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 43,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:15:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 44,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:17:58",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 44,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:15:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 45,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:18:03",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 45,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:15:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 46,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:18:08",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 46,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:15:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 47,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:18:13",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 47,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:15:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 48,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:18:18",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 48,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:15:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 49,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:18:23",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 49,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:15:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 50,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:18:28",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 50,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:15:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 51,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:18:33",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 51,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:16:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 52,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:18:38",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 52,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:16:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 53,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:19:01",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 54,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:16:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 54,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:19:01",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 55,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:16:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 55,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:19:01",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 56,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:16:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 56,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:19:01",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 53,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:16:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 57,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:19:03",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 58,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:16:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 58,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:19:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 57,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:16:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 59,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:19:13",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 59,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:16:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 60,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:19:18",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 60,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:16:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 61,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:19:23",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 61,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:16:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 62,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 62,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:16:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 63,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 69,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:17:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 64,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 63,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:17:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 65,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 64,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:17:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 66,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 65,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:17:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 67,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 66,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:17:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 68,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 67,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:17:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 69,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 68,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:17:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 70,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:08",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 70,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:17:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 71,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:13",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 71,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:17:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 72,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:18",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 72,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:17:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 73,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:23",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 73,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:17:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 74,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:28",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 74,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:17:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 75,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:33",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 75,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:18:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 76,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:38",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 76,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:18:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 77,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:43",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 77,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:18:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 78,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:48",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 78,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:18:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 79,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:53",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 79,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:18:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 80,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:20:58",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 80,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:18:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 81,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:21:03",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 81,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:18:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 82,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:21:11",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 82,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:18:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 83,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:21:13",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 83,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:18:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 84,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:21:22",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 84,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:18:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 85,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:21:23",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 85,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:18:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 86,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:21:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 86,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:18:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 87,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:21:33",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 87,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:19:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 88,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:21:38",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 88,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:19:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 89,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:21:43",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 89,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:19:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 90,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:21:48",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 90,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:19:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 91,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:21:53",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 91,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:19:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 92,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:21:58",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 92,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:19:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 93,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:22:03",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 93,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:19:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 94,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:22:08",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 94,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:19:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 95,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:22:13",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 95,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:19:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 96,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:22:18",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 96,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:19:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 97,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:22:23",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 97,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:19:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 98,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:22:28",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 98,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:19:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 99,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:22:33",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 99,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:20:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 100,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:22:38",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 100,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:20:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 101,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:22:43",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 101,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:20:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 102,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:22:48",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 102,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:20:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 103,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:22:53",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 103,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:20:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 104,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:22:58",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 104,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:20:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 105,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:23:03",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 105,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:20:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 106,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:23:08",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 106,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:20:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 107,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:23:13",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 107,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:20:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 108,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:23:18",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 108,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:20:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 109,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:23:23",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 109,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:20:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 110,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:23:28",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 110,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:20:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 111,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:23:33",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 111,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:21:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 112,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:23:38",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 112,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:21:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 113,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:23:43",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 113,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:21:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 114,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:23:48",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 114,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:21:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 115,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:23:53",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 115,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:21:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 116,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:23:58",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 116,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:21:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 117,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:24:03",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 117,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:21:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 118,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:24:08",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 118,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:21:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 119,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:24:13",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 119,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:21:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 120,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:24:18",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 120,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:21:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 121,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:24:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 121,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:21:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 122,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:24:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 122,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:21:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 123,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:24:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 123,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:22:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 124,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:24:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 124,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:22:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 125,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:24:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 125,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:22:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 126,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:24:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 126,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:22:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 127,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:24:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 127,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:22:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 128,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:24:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 128,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:22:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 129,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:25:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 129,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:22:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 130,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:25:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 130,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:22:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 131,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:25:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 131,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:22:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 132,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:25:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 132,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:22:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 133,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:25:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 133,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:22:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 134,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:25:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 134,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:22:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 135,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:25:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 135,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:23:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 136,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:25:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 136,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:23:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 137,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:25:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 137,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:23:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 138,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:25:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 138,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:23:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 139,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:25:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 139,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:23:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 140,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:25:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 140,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:23:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 141,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:26:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 141,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:23:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 142,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:26:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 142,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:23:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 143,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:26:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 143,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:23:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 144,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:26:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 144,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:23:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 145,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:26:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 145,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:23:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 146,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:26:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 146,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:23:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 147,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:26:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 147,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:24:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 148,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:26:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 148,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:24:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 149,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:26:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 149,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:24:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 150,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:26:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 150,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:24:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 151,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:26:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 151,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:24:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 152,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:26:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 152,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:24:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 153,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:27:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 153,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:24:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 154,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:27:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 154,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:24:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 155,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:27:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 155,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:24:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 156,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:27:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 156,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:24:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 157,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:27:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 157,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:24:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 158,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:27:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 158,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:24:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 159,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:27:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 159,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:25:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 160,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:27:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 160,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:25:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 161,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:27:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 161,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:25:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 162,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:27:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 162,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:25:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 163,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:27:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 163,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:25:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 164,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:27:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 164,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:25:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 165,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:28:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 165,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:25:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 166,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:28:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 166,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:25:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 167,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:28:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 167,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:25:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 168,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:28:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 168,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:25:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 169,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:28:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 169,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:25:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 170,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:28:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 170,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:25:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 171,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:28:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 171,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:26:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 172,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:28:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 172,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:26:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 173,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:28:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 173,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:26:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 174,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:28:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 174,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:26:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 175,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:28:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 175,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:26:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 176,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:28:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 176,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:26:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 177,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:29:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 177,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:26:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 178,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:29:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 178,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:26:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 179,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:29:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 179,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:26:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 180,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:29:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 180,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:26:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 181,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:29:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 181,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:26:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 182,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:29:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 182,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:26:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 183,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:29:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 183,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:27:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 184,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:29:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 184,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:27:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 185,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:29:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 185,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:27:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 186,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:29:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 186,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:27:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 187,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:29:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 187,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:27:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 188,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:29:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 188,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:27:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 189,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:30:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 189,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:27:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 190,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:30:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 190,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:27:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 191,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:30:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 191,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:27:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 192,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:30:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 192,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:27:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 193,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:30:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 193,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:27:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 194,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:30:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 194,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:27:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 195,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:30:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 195,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:28:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 196,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:30:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 196,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:28:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 197,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:30:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 197,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:28:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 198,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:30:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 198,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:28:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 199,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:30:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 199,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:28:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 200,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:30:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 200,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:28:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 201,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:31:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 201,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:28:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 202,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:31:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 202,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:28:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 203,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:31:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 203,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:28:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 204,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:31:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 204,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:28:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 205,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:31:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 205,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:28:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 206,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:31:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 206,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:28:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 207,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:31:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 207,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:29:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 208,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:31:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 208,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:29:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 209,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:31:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 209,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:29:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 210,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:31:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 210,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:29:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 211,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:31:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 211,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:29:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 212,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:31:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 212,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:29:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 213,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:32:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 213,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:29:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 214,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:32:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 214,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:29:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 215,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:32:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 215,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:29:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 216,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:32:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 216,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:29:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 217,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:32:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 217,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:29:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 218,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:32:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 218,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:29:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 219,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:32:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 219,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:30:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 220,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:32:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 220,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:30:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 221,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:32:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 221,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:30:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 222,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:32:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 222,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:30:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 223,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:32:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 223,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:30:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 224,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:32:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 224,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:30:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 225,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:33:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 225,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:30:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 226,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:33:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 226,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:30:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 227,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:33:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 227,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:30:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 228,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:33:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 228,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:30:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 229,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:33:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 229,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:30:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 230,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:33:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 230,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:30:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 231,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:33:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 231,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:31:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 232,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:33:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 232,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:31:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 233,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:33:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 233,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:31:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 234,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:33:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 234,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:31:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 235,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:33:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 235,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:31:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 236,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:33:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 236,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:31:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 237,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:34:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 237,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:31:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 238,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:34:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 238,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:31:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 239,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:34:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 239,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:31:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 240,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:34:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 240,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:31:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 241,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:34:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 241,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:31:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 242,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:34:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 242,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:31:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 243,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:34:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 243,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:32:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 244,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:34:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 244,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:32:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 245,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:34:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 245,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:32:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 246,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:34:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 246,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:32:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 247,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:34:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 247,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:32:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 248,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:34:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 248,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:32:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 249,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:35:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 249,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:32:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 250,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:35:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 250,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:32:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 251,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:35:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 251,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:32:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 252,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:35:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 252,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:32:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 253,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:35:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 253,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:32:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 254,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:35:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 254,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:32:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 255,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:35:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 255,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:33:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 256,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:35:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 256,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:33:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 257,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:35:45",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 257,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:33:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 258,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:35:50",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 258,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:33:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 259,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:35:55",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 259,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:33:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 260,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:36:00",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 260,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:33:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 261,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:36:05",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 261,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:33:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 262,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:36:10",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 262,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:33:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 263,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:36:15",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 263,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:33:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 264,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:36:20",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 264,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:33:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 265,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:36:25",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 265,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:33:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 266,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:36:30",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 266,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:33:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 267,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:36:35",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 267,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:34:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 268,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:36:40",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 268,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:34:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 269,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:36:45",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 269,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:34:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 270,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:36:50",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 270,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:34:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 271,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:36:55",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 271,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:34:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 272,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:37:00",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 272,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:34:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 273,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:37:05",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 273,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:34:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 274,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:37:10",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 274,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:34:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 275,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:37:15",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 275,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:34:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 276,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:37:20",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 276,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:34:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 277,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:37:25",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 277,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:34:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 278,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:37:30",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 278,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:34:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 279,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:37:35",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 279,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:35:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 280,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:37:40",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 280,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:35:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 281,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:37:45",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 281,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:35:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 282,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:37:50",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 282,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:35:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 283,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:37:55",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 283,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:35:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 284,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:38:00",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 284,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:35:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 285,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:38:05",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 285,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:35:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 286,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:38:10",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 286,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:35:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 287,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:38:15",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 287,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:35:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 288,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:38:20",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 288,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:35:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 289,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:38:25",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 289,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:35:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 290,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:38:30",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 290,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:35:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 291,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:38:35",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 291,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:36:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 292,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:38:40",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 292,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:36:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 293,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:38:45",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 293,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:36:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 294,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:38:50",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 294,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:36:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 295,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:38:55",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 295,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:36:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 296,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:39:00",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 296,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:36:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 297,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:39:05",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 297,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:36:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 298,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:39:10",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 298,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:36:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 299,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:39:15",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 299,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:36:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 300,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:39:20",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 300,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:36:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 301,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:39:25",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 301,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:36:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 302,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:39:30",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 302,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:36:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 303,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:39:35",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 303,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:37:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 304,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:39:40",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 304,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:37:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 305,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:39:45",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 305,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:37:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 306,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:39:50",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 306,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:37:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 307,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:39:55",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 307,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:37:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 308,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:40:00",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 308,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:37:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 309,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:40:05",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 309,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:37:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 310,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:40:10",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 310,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:37:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 311,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:40:15",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 311,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:37:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 312,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:40:20",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 312,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:37:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 313,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:40:25",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 313,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:37:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 314,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:40:30",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 314,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:37:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 315,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:40:35",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 315,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:38:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 316,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:40:40",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 316,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:38:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 317,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:40:45",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 317,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:38:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 318,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:40:50",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 318,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:38:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 319,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:40:55",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 319,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:38:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 320,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:41:00",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 320,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:38:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 321,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:41:05",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 321,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:38:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 322,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:41:10",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 322,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:38:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 323,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:41:15",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 323,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:38:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 324,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:41:20",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 324,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:38:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 325,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:41:25",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 325,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:38:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 326,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:41:30",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 326,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:38:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 327,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:41:35",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 327,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:39:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 328,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:41:40",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 328,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:39:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 329,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:41:45",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 329,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:39:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 330,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:41:50",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 330,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:39:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 331,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:41:55",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 331,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:39:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 332,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:42:00",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 332,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:39:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 333,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:42:05",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 333,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:39:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 334,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:42:10",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 334,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:39:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 335,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:42:15",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 335,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:39:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 336,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:42:20",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 336,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:39:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 337,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:42:25",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 337,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:39:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 338,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:42:30",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 338,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:39:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 339,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:42:35",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 339,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:40:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 340,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:42:40",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 340,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:40:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 341,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:42:45",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 341,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:40:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 342,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:42:50",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 342,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:40:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 343,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:42:55",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 343,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:40:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 344,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:43:00",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 344,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:40:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 345,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:43:05",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 345,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:40:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 346,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:43:10",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 346,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:40:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 347,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:43:15",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 347,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:40:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 348,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:43:20",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 348,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:40:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 349,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:43:25",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 349,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:40:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 350,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:43:30",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 350,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:40:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 351,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:43:35",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 351,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:41:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 352,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:43:40",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 352,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:41:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 353,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:43:45",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 353,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:41:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 354,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:43:50",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 354,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:41:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 355,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:43:55",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 355,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:41:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 356,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:44:00",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 356,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:41:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 357,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:44:05",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 357,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:41:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 358,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:44:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 358,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:41:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 359,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:44:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 359,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:42:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 360,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:44:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 360,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:42:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 361,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:44:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 361,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:42:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 362,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:44:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 362,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:42:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 363,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:44:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 363,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:42:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 364,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:45:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 364,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:42:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 365,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:45:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 365,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:42:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 366,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:45:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 366,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:42:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 367,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:45:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 367,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:42:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 368,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:45:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 368,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:42:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 369,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:45:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 369,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:42:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 370,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:45:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 370,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:42:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 371,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:45:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 371,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:43:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 372,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:45:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 372,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:43:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 373,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:45:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 373,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:43:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 374,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:45:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 374,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:43:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 375,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:45:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 375,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:43:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 376,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:46:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 376,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:43:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 377,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:46:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 377,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:43:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 378,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:46:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 378,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:43:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 379,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:46:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 379,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:43:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 380,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:46:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 380,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:43:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 381,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:46:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 381,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:43:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 382,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:46:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 382,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:43:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 383,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:46:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 383,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:44:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 384,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:46:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 384,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:44:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 385,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:46:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 385,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:44:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 386,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:46:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 386,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:44:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 387,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:46:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 387,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:44:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 388,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:47:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 388,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:44:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 389,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:47:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 389,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:44:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 390,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:47:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 390,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:44:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 391,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:47:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 391,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:44:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 392,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:47:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 392,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:44:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 393,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:47:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 393,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:44:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 394,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:47:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 394,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:44:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 395,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:47:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 395,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:45:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 396,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:47:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 396,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:45:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 397,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:47:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 397,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:45:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 398,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:47:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 398,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:45:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 399,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:47:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 399,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:45:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 400,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:48:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 400,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:45:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 401,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:48:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 401,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:45:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 402,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:48:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 402,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:45:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 403,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:48:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 403,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:45:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 404,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:48:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 404,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:45:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 405,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:48:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 405,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:45:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 406,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:48:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 406,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:45:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 407,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:48:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 407,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:46:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 408,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:48:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 408,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:46:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 409,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:48:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 409,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:46:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 410,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:48:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 410,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:46:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 411,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:48:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 411,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:46:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 412,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:49:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 412,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:46:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 413,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:49:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 413,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:46:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 414,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:49:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 414,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:46:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 415,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:49:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 415,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:46:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 416,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:49:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 416,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:46:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 417,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:49:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 417,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:46:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 418,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:49:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 418,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:46:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 419,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:49:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 419,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:47:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 420,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:49:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 420,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:47:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 421,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:49:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 421,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:47:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 422,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:49:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 422,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:47:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 423,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:49:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 423,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:47:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 424,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:50:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 424,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:47:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 425,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:50:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 425,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:47:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 426,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:50:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 426,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:47:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 427,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:50:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 427,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:47:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 428,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:50:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 428,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:47:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 429,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:50:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 429,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:47:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 430,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:50:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 430,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:47:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 431,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:50:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 431,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:48:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 432,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:50:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 432,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:48:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 433,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:50:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 433,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:48:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 434,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:50:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 434,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:48:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 435,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:50:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 435,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:48:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 436,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:51:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 436,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:48:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 437,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:51:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 437,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:48:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 438,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:51:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 438,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:48:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 439,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:51:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 439,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:48:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 440,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:51:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 440,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:48:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 441,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:51:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 441,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:48:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 442,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:51:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 442,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:48:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 443,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:51:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 443,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:49:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 444,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:51:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 444,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:49:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 445,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:51:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 445,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:49:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 446,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:51:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 446,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:49:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 447,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:51:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 447,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:49:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 448,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:52:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 448,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:49:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 449,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:52:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 449,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:49:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 450,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:52:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 450,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:49:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 451,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:52:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 451,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:49:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 452,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:52:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 452,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:49:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 453,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:52:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 453,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:49:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 454,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:52:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 454,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:49:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 455,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:52:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 455,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:50:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 456,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:52:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 456,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:50:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 457,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:52:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 457,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:50:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 458,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:52:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 458,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:50:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 459,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:52:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 459,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:50:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 460,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:53:04",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 460,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:50:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 461,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:53:09",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 461,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:50:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 462,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:53:14",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 462,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:50:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 463,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:53:19",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 463,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:50:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 464,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:53:24",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 464,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:50:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 465,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:53:29",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 465,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:50:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 466,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:53:34",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 466,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:50:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 467,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:53:39",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 467,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:51:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 468,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:53:44",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 468,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:51:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 469,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:53:49",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 469,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:51:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 470,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:53:54",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 470,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:51:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 471,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:53:59",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 471,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:51:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 472,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:54:05",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 472,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:51:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 473,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:54:10",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 473,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:51:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 474,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:54:15",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 474,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:51:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 475,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:54:20",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 475,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:51:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 476,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:54:25",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 476,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:51:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 477,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:54:30",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 477,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:51:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 478,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:54:35",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 478,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:51:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 479,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:54:40",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 479,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:52:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 480,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:54:45",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 480,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:52:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 481,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:54:50",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 481,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:52:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 482,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:54:55",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 482,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:52:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 483,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:55:00",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 483,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:52:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 484,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:55:05",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 484,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:52:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 485,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:55:10",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 485,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:52:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 486,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:55:15",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 486,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:52:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 487,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:55:20",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 487,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:52:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 488,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:55:25",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 488,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:52:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 489,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:55:30",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 489,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:52:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 490,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:55:35",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 490,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:52:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 491,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:55:40",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 491,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:53:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 492,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:55:45",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 492,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:53:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 493,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:55:50",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 493,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:53:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 494,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:55:55",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 494,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:53:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 495,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:56:00",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 495,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:53:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 496,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:56:05",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 496,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:53:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 497,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:56:10",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 497,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:53:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 498,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:56:15",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 498,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:53:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 499,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:56:20",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 499,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:53:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 500,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:56:25",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 500,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:53:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 501,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:56:30",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 501,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:53:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 502,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:56:35",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 502,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:53:56",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 503,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:56:40",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 503,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:54:01",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 504,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:56:45",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 504,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:54:06",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 505,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:56:50",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 505,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:54:11",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 506,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:56:55",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 506,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:54:16",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 507,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:57:00",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 507,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:54:21",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 508,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:57:05",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 508,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:54:26",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 509,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:57:10",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 509,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:54:31",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 510,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:57:15",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 510,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:54:36",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 511,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:57:20",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 511,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:54:41",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 512,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:57:25",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 512,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:54:46",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  },
  {
    "lng": 112.5729846242,
    "statusdescription": "",
    "dpf_before": "99.60",
    "alt": 300,
    "smoke_value": "28.32",
    "dpf_after": "39.70",
    "dpf_change": "67.70",
    "dir": 200,
    "burner_before": "123.60",
    "speed": 60,
    "urea": "98.00",
    "rownum": 513,
    "locationdescription": "湖北省荆州市江陵县白马寺镇S53枣石高速头陀寺南240米",
    "recvtime": "2020-07-06 19:57:30",
    "av": 1,
    "dpf_grainafter": "13.19",
    "docpa_change": "123.60",
    "id": 513,
    "vehicleId": 9671,
    "gpstime": "2020-07-06 19:54:51",
    "doc_before": "27.50",
    "dpf_grainbefore": "23.91",
    "lat": 30.1176083472,
    "status": 0
  }
]

export default {
  mapTrackTableData: function () {
    return mapTrackTableData
  }
}
