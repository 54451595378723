<template>
  <div id="app">
<!--    <router-view/>-->
    <Test_MapTrack></Test_MapTrack>
  </div>

</template>

<script>
import Test_MapTrack from "@/components/Test_MapTrack";
export default {
  name: 'App',
  mounted(){

  },
  components: {
    Test_MapTrack,
  },
  data(){
    return{

    }
  },
  methods: {

  }
}
</script>

<style>

</style>
