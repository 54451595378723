<template>
<div>
  <div class="box">
    <div class="header">Header</div>
    <div class="big-main">
      <div class="aside">Aside</div>
      <div>
        <div class="main">Main</div>
        <div class="footer">Footer</div>
      </div>
    </div>
    <div class="box-text">多多</div>
  </div>
</div>
</template>

<script>
export default {
  name: "Layout"
}
</script>

<style scoped lang="scss">
</style>
